import UTILS from "./utils";

const baseUrl = "/v0.3"

// la richiesta funziona, bisogna solo farne await e scaricare l'immagine
// https://sally.linksfoundation.com/v0.1/image?path=output/gen/1671453345.121336/seed3527_hr.png


// function getImage(url){
//   return url.split("?")[1].split("=")[1]
// }

// async function getImage(url){
//   const img_path = url.split("?")[1].split("=")[1]
//     return new Promise((resolve, reject)=>{
//         fetch(baseUrl + "/image?path="+img_path).then(response => {
//             if (response.ok) {
//                 response.text().then((res) => {
//                 console.log(res)
//                 localStorage.setItem(img_path, res)
//                 resolve(img_path)
//                 } )
//             }
//             else{
//                 reject("Error")
//             }
//             }).catch((err)=>{reject(err)})
// })
    
// }

async function SendMusic(blobURL, workId, model, style){
  const csrf = document.cookie.match('(^|;)\\s*' + "csrf_access_token" + '\\s*=\\s*([^;]+)')?.pop() || ''
    // console.log("BLOB...", blobURL)
    let blob = await fetch(blobURL).then(r => r.blob());
    let data = new FormData();
    let model_name = model ? "SG" : "DF"
    let style_txt = ""
    if (model_name == "DF"){
      style_txt = style ? style : "abstract"
    }
    console.log("style", style_txt)
    console.log("model", model_name)
	data.append('file', new File([blob], "music.mp3"));
    return new Promise((resolve, reject) => {
      fetch( baseUrl+ "/work/"+ workId +"/music" + "?model=" + model_name +"&style=" + style_txt, {
        headers: {
          'X-CSRF-Token': csrf
        },
        method: 'POST',
        body: data, 
        mode: 'cors',
        credentials: "same-origin"
      }).then((response) => {
        if (response.status == 200) {
          response.json().then((res) => {
            resolve(res)
          });
        }
        else {
          let status = response.status
          // analyze the cause of error
          response
            .text()
            .then((res) => {
            reject({status: status, message: res})
            })
            .catch((err) => {
            reject({status: 500, message: err})});
        }
      }).catch((err)=>{
        reject({status: 500, message: err})
      })})
}

const getCookieValue = (name) => (
  document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
)

async function getEmotion(blobURL){
  const csrf = document.cookie.match('(^|;)\\s*' + "csrf_access_token" + '\\s*=\\s*([^;]+)')?.pop() || ''
  // console.log(csrf)
  // console.log("BLOB...", blobURL)
  let blob = await fetch(blobURL).then(r => r.blob());
  let data = new FormData();
  data.append('file', new File([blob], "music.mp3"));

  const request = new Request(baseUrl+ "/color", {
    headers: {
      'X-CSRF-Token': csrf
    },
    method: 'POST',
    body: data, 
    mode: 'cors',
    credentials: "same-origin"
  });
  // console.log(request)
  // console.log(request.headers.get('X-CSRF-Token'))
    return new Promise((resolve, reject) => {
        fetch(request)
    .then((response) => {if (response.ok) {
        response.json().then((res) => {
          // console.log(res)
          resolve(res)
        });
      } else {
        // analyze the cause of error
        let status = response.status
        response
          .text()
          .then((res) => {
          // console.log(res)
          reject({status: status, message: res})
          }) // error msg in the response body
            .catch((err) => {
            reject({status: 500, message: err})}); // something else
      }
    }).catch((err)=>{
        reject({status: 500, message: err})
    })})
}


async function signup(){

}

async function requestAccessTokenFromGoogleIdToken(googleIdToken){
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/auth", {headers: {
      "Authorization" : googleIdToken
    }}).then((res)=> {
      if (res.ok){
        res.json().then((res)=>{
          resolve(res)
        }).catch((err)=>{
          reject({status: 500, message: err})
        })
      }
      else{
        let status = res.status
        res.text().then((res) => {
          reject({status: status, message: res})
        }) // error msg in the response body
        .catch((err) => {
        reject({status: 500, message: err})}); // something else
      }
    }).catch((err)=>{
      reject({status: 500, message: err})
    })
  })
}

async function logout(){
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/logout", {credentials: 'include'}).then((res)=> {
      if (res.ok){
        res.json().then((res)=>{
          resolve(res)
        }).catch((err)=>{
          reject({status: 500, message: err})
        })
      }
      else{
        let status = res.status
        res.text().then((res) => {
          reject({status: status, message: res})
        }) // error msg in the response body
        .catch((err) => {
        reject({status: 500, message: err})}); // something else
      }
    }).catch((err)=>{
      reject({status: 500, message: err})
    })
  })
}

async function getUser(){
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/user", {credentials: 'include'}).then((res)=> {
      if (res.ok){
        res.json().then((res)=>{
          // console.log(res)
          resolve(res)
        }).catch((err)=>{
          reject({status: 500, message: err})
        })
      }
      else{
        let status = res.status
        res.text().then((res) => {
          reject({status: status, message: res})
        })
        .catch((err) => {
        reject({status: 500, message: err})}); // something else
      }
    }).catch((err)=>{
      reject({status: 500, message: err})
    })
  })
}

async function getWorks(){
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/work", {credentials: 'include'}).then((res)=> {
      if (res.ok){
      res.json().then((res)=>{
        // console.log(res)      
        resolve(res)
      }).catch((err)=>{
        reject({status: 500, message: err})
      })}
      else{
        let status = res.status
        res.text().then((res) => {
          
          reject({status: status, message: res})
        }) // error msg in the response body
        .catch((err) => {
        reject({status: 500, message: err})}); // something else
      }
    }).catch((err)=>{
      reject({status: 500, message: err})
    })
  })
}

async function openWork(name, model, style){
  console.log(style)
  const csrf = document.cookie.match('(^|;)\\s*' + "csrf_access_token" + '\\s*=\\s*([^;]+)')?.pop() || ''
  let data = ""
  if (model === 0){
    data = JSON.stringify({"work_name": name, "model": "DF", "style": style})
  }
  else{
    data = JSON.stringify({"work_name": name, "model": "SG"})
  }
  
  // console.log(data)
  const request = new Request(baseUrl+ "/work", {
    headers: {
      'X-CSRF-Token': csrf,
      "Content-type": "application/json",
    },
    method: 'POST',
    body: data,
    mode: 'cors',
    credentials: "same-origin",
    
  });
    return new Promise((resolve, reject) => {
        fetch(request)
    .then((response) => {
      
      if (response.status == 200) {
        response.json().then((res) => {
          // console.log(res)
          resolve(res)
        });
      }
      else{ 
        // analyze the cause of error
        let status = response.status
        response
          .text()
          .then((res) => {
          // console.log(res)
          reject({status: status, message: res})
          }) // error msg in the response body
            .catch((err) => {
            reject({status: 500, message: err})}); // something else
      }
    }).catch((err)=>{
        reject({status: 500, message: err})
    })})
}

async function closeWork(work_id){
  const csrf = document.cookie.match('(^|;)\\s*' + "csrf_access_token" + '\\s*=\\s*([^;]+)')?.pop() || ''
  const request = new Request(baseUrl+ "/work/"+ work_id, {
    headers: {
      'X-CSRF-Token': csrf,
    },
    method: 'PUT',
    mode: 'cors',
    credentials: "same-origin",
  });
    return new Promise((resolve, reject) => {
        fetch(request)
    .then((response) => {if (response.ok) {
        response.json().then((res) => {
          // console.log(res)
          resolve(res)
        });
      } else {
        // analyze the cause of error
        let status = response.status
        response
          .text()
          .then((res) => {
          // console.log(res)
          reject({status: status, message: res})
          }) // error msg in the response body
            .catch((err) => {
            reject({status: 500, message: err})}); // something else
      }
    }).catch((err)=>{
        reject({status: 500, message: err})
    })})
}


async function getImage(workId, imageId){
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/work/"+ workId +"/image/"+ imageId, {credentials: 'include'}).then((res)=> {
      if (res.ok){
        res.json().then((res)=>{
          const data = res.image;
          const blob = UTILS.base64ToBlob(data); 
          const url = URL.createObjectURL(blob)
          resolve(url)
        }).catch((err) => {
          reject({status: 500, message: err})});
      }
      else{
        let status = res.status
        res.text().then((res) => {
          reject({status: status, message: res})
        })
        .catch((err) => {
        reject({status: 500, message: err})}); // something else
      }
    }).catch((err)=>{
      reject({status: 500, message: err})
    })
  })

}

async function getThumbnail(workId, imageId){
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/work/"+ workId +"/thumbnail/"+ imageId, {credentials: 'include'}).then((res)=> {
      if (res.ok){
        res.json().then((res)=>{
          const data = res.image;
          const blob = UTILS.base64ToBlob(data); 
          const url = URL.createObjectURL(blob)
          resolve(url)
        }).catch((err) => {
          reject({status: 500, message: err})});
      }
      else{
        let status = res.status
        res.text().then((res) => {
          reject({status: status, message: res})
        })
        .catch((err) => {
        reject({status: 500, message: err})}); // something else
      }
    }).catch((err)=>{
      reject({status: 500, message: err})
    })
  })

}


async function getWorkImages(workId){
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/work/"+ workId +"/image", {credentials: 'include'}).then((res)=> {
      if (res.ok){
        res.json().then((res)=>{
          // console.log(res)
          const data_images = res.images;
          const data_thumbnails = res.thumbnails;
          const images = []
          const thumbnails = []
          for (let i=0; i<data_images.length; i++){
            const blob = UTILS.base64ToBlob(data_images[i]);
            const url = URL.createObjectURL(blob)
            images.push(url)
          }
          for (let i=0; i<data_thumbnails.length; i++){
            const blob = UTILS.base64ToBlob(data_thumbnails[i]);
            const url = URL.createObjectURL(blob)
            thumbnails.push(url)
          }
          resolve({"images": images, "thumbnails": thumbnails})
        }).catch((err) => {
          reject({status: 500, message: err})});
      }
      else{
        let status = res.status
        res.text().then((res) => {
          reject({status: status, message: res})
        })
        .catch((err) => {
        reject({status: 500, message: err})}); // something else
      }
    }).catch((err)=>{
      reject({status: 500, message: err})
    })
  })

}



async function getWork(workId){
  return new Promise((resolve, reject) => {
    fetch(baseUrl + "/work/"+ workId, {credentials: 'include'}).then((res)=> {
      if (res.ok){
        res.json().then((res)=>{
          // console.log(res)
          resolve(res)
        }).catch((err) => {
          reject({status: 500, message: err})});
      }
      else{
        let status = res.status
        res.text().then((res) => {
          reject({status: status, message: res})
        })
        .catch((err) => {
        reject({status: 500, message: err})}); // something else
      }
    }).catch((err)=>{
      reject({status: 500, message: err})
    })
  })

}


async function modifyWorkName(work_id, new_name){
  const csrf = document.cookie.match('(^|;)\\s*' + "csrf_access_token" + '\\s*=\\s*([^;]+)')?.pop() || ''
  const request = new Request(baseUrl+ "/work/"+ work_id + "/name", {
    headers: {
      'X-CSRF-Token': csrf,
      'Content-Type': 'application/json'
    },
    method: 'PUT',
    body: JSON.stringify({"work_name": new_name}),
    mode: 'cors',
    credentials: "same-origin",
  });
    return new Promise((resolve, reject) => {
        fetch(request)
    .then((response) => {if (response.ok) {
        response.json().then((res) => {
          // console.log(res)
          resolve(res)
        });
      } else {
        // analyze the cause of error
        let status = response.status
        response
          .text()
          .then((res) => {
          // console.log(res)
          reject({status: status, message: res})
          }) // error msg in the response body
            .catch((err) => {
            reject({status: 500, message: err})}); // something else
      }
    }).catch((err)=>{
        reject({status: 500, message: err})
    })})
}


async function deleteWork(work_id){
  const csrf = document.cookie.match('(^|;)\\s*' + "csrf_access_token" + '\\s*=\\s*([^;]+)')?.pop() || ''
  const request = new Request(baseUrl+ "/work/"+ work_id, {
    headers: {
      'X-CSRF-Token': csrf,
    },
    method: 'DELETE',
    mode: 'cors',
    credentials: "same-origin",
  });
    return new Promise((resolve, reject) => {
        fetch(request)
    .then((response) => {if (response.ok) {
        response.json().then((res) => {
          // console.log(res)
          resolve(res)
        });
      } else {
        // analyze the cause of error
        let status = response.status
        response
          .text()
          .then((res) => {
          // console.log(res)
          reject({status: status, message: res})
          }) // error msg in the response body
            .catch((err) => {
            reject({status: 500, message: err})}); // something else
      }
    }).catch((err)=>{
        reject({status: 500, message: err})
    })})
}
const API = {
   SendMusic,
   getImage,
   getThumbnail,
   getWorkImages,
   getEmotion,
   signup,
   logout,
   getWorks, 
   getWork,
   getUser,
   openWork,
   closeWork,
   requestAccessTokenFromGoogleIdToken,
   modifyWorkName,
   deleteWork
  };
  export default API;