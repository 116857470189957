import { useReactMediaRecorder } from "react-media-recorder";
import React, { useEffect, useState } from "react";
import Button from 'react-bootstrap/Button'
import Alert from 'react-bootstrap/Alert'
import Spinner from 'react-bootstrap/Spinner'
import {BiPause, BiStop, BiMicrophone} from "react-icons/bi"
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import API from "../api/api";
import {useTranslation} from 'react-i18next';
import Wave from 'react-wavify';

// const getAPIBaseUrl = function(path){
//   console.log(path)
//   if (path === "/live"){
//     return "/v0.2a"
//   }
//   else{
//     return "/v0.2"
//   }
// }

const RecordView = (props) => {
  const {t} = useTranslation()
  const [second, setSecond] = useState("00");
  const [microRecSecond, setMicroRecSecond] = useState(0);
  const [minute, setMinute] = useState("00");
  const [isActive, setIsActive] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const [recStarted, setRecStarted] = useState(false);
  const [counter, setCounter] = useState(0);
  const [lastURL, setLastURL] = useState(undefined)
  const [allURL, setAllURL] = useState(undefined)

  
  useEffect(() => {
    let intervalId;
    if (isActive) {
      
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);
        setMicroRecSecond(microRecSecond + 1)
        if (microRecSecond === 30) {
            // console.log("MicroREC stopped")
            microRecController.stopRecording()
            setMicroRecSecond(0)
            microRecController.startRecording()
        }

        let computedSecond =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : secondCounter;
        let computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : minuteCounter;

        setSecond(computedSecond);
        setMinute(computedMinute);

        setCounter((counter) => counter + 1);
      }, 1000);
    }
        return () => clearInterval(intervalId);
    }, [isActive, counter]);


    useEffect(()=>{
      if (lastURL !== undefined ){
        // v0.2
        props.setIsLoading(true)
        API.getEmotion(lastURL).then((res)=>{
          const emotion=res["color_id"]
          // console.log(emotion)
          
          props.setEmotion(emotion)
          props.setServerErr(false)
          props.setServerErrMsg(null)
          API.SendMusic(lastURL, props.workId, props.model, props.style).then((res)=>{
            // console.log(emotion)
            // console.log(res)
            props.updateImages(res.image_id, emotion)
            props.setIsLoading(false)
            props.setServerErr(false)
            props.setServerErrMsg(null)
            if(!recStarted){
              props.setIsWorkActive(false)
            }
        }).catch((err)=>{
          console.log(err)
          
          if (err.status === 204){
            props.setServerErrMsg(t("204"))
          }
          else{
            props.setServerErrMsg(t("500") + "-" + err.message)
          }
          props.setServerErr(true)
          // props.setServerErrMsg(err.message)
          setIsActive(false);
          setIsPaused(false);
          setRecStarted(false);
          pauseRecording();
          microRecController.pauseRecording();
          stopRecording();
          microRecController.stopRecording();
          stopTimer();
          props.stopUpdateColors();
          props.setIsLoading(false)           
        })
        }).catch((err)=>{
          // console.log(err)
          
          if (err.status === 204){
            props.setServerErrMsg(t("204"))
          }
          else{
              props.setServerErrMsg(t("500") + "-" + err.message)
          }
          props.setServerErr(true)
          // props.setServerErrMsg(err.message)
          setIsActive(false);
          setIsPaused(false);
          setRecStarted(false);
          pauseRecording();
          microRecController.pauseRecording();
          stopRecording();
          microRecController.stopRecording();
          stopTimer();
          props.setIsLoading(false)
        })

        //v0.1
        // API.SendMusic(lastURL).then((res)=>{
        //   console.log(res)
        //   props.updateImages(res)
        // }).catch((err)=>{
        //   console.log(err)
        // })
      }
    }, [lastURL])

    

    useEffect(()=>{
      if (allURL){
        // console.log("setting "+ allURL)
      }
    }, [allURL])

    function stopTimer() {
        setIsActive(false);
        setIsPaused(true);
        setRecStarted(false)
        props.setRecStarted(false)
        setCounter(0);
        setSecond("00");
        setMinute("00");
      }

    const getWorkId = () => {
     
        API.openWork("", props.model, !props.style ? "abstract": props.style ).then((res)=>{
            console.log(res)
            props.setWorkId(res.work)
        }).catch((err)=>{
            if (err.status === 204 ){
              props.setServerErrMsg(t("204"))
            }
            else{
              props.setServerErrMsg(t("500") + " - " + err.message)  
            }
            props.setServerErr(true)
            
            setIsActive(false);
            setIsPaused(false);
            setRecStarted(false);
            pauseRecording();
            microRecController.pauseRecording();
            stopRecording();
            microRecController.stopRecording();
            stopTimer();
            props.stopUpdateColors();   
            props.setIsWorkActive(false);      
        })
    }

  
    
    
    const microRecController = useReactMediaRecorder({
        video: false,
        audio: true,
        echoCancellation: true,
        blobPropertyBag: {type: 'audio/mpeg'}
      });

    const {
        startRecording,
        stopRecording,
        pauseRecording,
        mediaBlobUrl
      } = useReactMediaRecorder({
        video: false,
        audio: true,
        echoCancellation: true,
        blobPropertyBag: {type: 'audio/mpeg'}
      });
      // console.log("status", status)
      


      if(mediaBlobUrl !== allURL ){
        console.log("Final Record URL", mediaBlobUrl);  
        setAllURL(mediaBlobUrl)
      }
      if (microRecController.mediaBlobUrl !== lastURL){
        setLastURL(microRecController.mediaBlobUrl)
      }
      

    return (<>
          <Row className="justify-content-center">       
           
              
            <Col md={recStarted ? 4 : 1} sm={recStarted ? 4 : 1}  className="justify-content-center">
              <Row className="justify-content-center text-center">   
              {/* <video src={lastURL} controls autoPlay loop /> */}
                  {(recStarted && isActive) ? 
                          <> <Col md={2} sm={2} xs={2} xl={2} lg={2} xxl={2}>
                              <Spinner size="sm"  animation="grow" variant="danger"/>
                              <span className="text-box"> &nbsp; {minute}:{second}</span>
                          </Col></> : <></>}
                  { (recStarted && isPaused) ? 
                          <> <Col md={2} sm={2} xs={2} xl={2} lg={2} xxl={2}>
                              <BiPause height={"2em"} width={"2em"} /> <span className="text-box">&nbsp; {minute}:{second}</span>
                        </Col></>: <></>
                  }  
                <Col className="justify-content-center">
                  <Button variant={isActive ? "warning":"success"} className="round-btn no-background" onClick={()=>{
                        if (!recStarted){
                            props.setIsWorkActive(true)
                            getWorkId()
                            setRecStarted(true)
                            props.setRecStarted(true)
                            props.updateColors()
                        }
                        if(!isActive){
                            startRecording();
                            if (isPaused){
                              microRecController.resumeRecording()
                            }
                            else{
                              microRecController.startRecording();
                            }
                            setIsPaused(false);
                            props.setRecPaused(false)
                        }else{
                            pauseRecording();
                            microRecController.pauseRecording();
                            setIsPaused(true);
                            props.setRecPaused(true)
                            props.stopUpdateColors()
                            
                        }
                        setIsActive(!isActive);
                      
                        
                  }}>
                    {isActive ?
                      <BiPause className="icon"/> :
                      <img className="icon icon-with-border" src="resources/microphone-unscreen.gif" alt="" width="48" height="48"/>}
                  </Button>
                </Col>
                      
                { isActive? 
                  <Col md={4} sm={4} xs={4} xl={4} lg={4} xxl={4} className="justify-content-left">
                    <Button variant="warning" className="round-btn no-background" onClick={()=>{
                        pauseRecording();
                        microRecController.pauseRecording();
                        stopRecording();
                        microRecController.stopRecording();
                        stopTimer();
                        props.stopUpdateColors();
                        
                    }}>
                      <BiStop height={"2em"} width={"2em"} className="icon"/>
                    </Button> 
                  </Col>: <></>}
              </Row>
            </Col>
             </Row>       
            {recStarted ? 
              <Row  className="rec-alert">
                
                <Col>
                  <Wave fill='url(#gradient)' className="wave"
                      paused={isPaused}
                      options={{
                        height: 80,
                        amplitude: 15,
                        speed: 0.40,
                        points: 5}}>
                        <defs>
                            <linearGradient id="gradient" gradientTransform="rotate(90)">
                            <stop offset="50%"  stopColor="#82c04499" />
                            <stop offset="90%" stopColor="#08587777" />
                            </linearGradient>
                        </defs>
                  </Wave>
                </Col>
                
              </Row> : <></>}
        
        </>
      
    )


}
export default RecordView